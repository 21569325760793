import React, { useState, useEffect, useCallback, useContext, useRef, Fragment, Children } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Cookies from 'cookies';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import uniqBy from 'lodash/uniqBy';
import DangerousHTML from '../components/DangerousHTML';
import getDefaultTab from '../helpers/getDefaultTab';
import * as WEBSITES from '../constants/website';
import GanjaGodessPhoneNumber from '../components/DTC/ganjagodess/GanjaGodessPhoneNumber';
import Banner, { BannerImage } from '../components/BannerNew';
import BannerCustom from '../components/BannerCustom';
import AddressBox from '../components/AddressBox';
import Header from '../components/Header';
import HraderDeliveryAddress from '../components/HraderDeliveryAddress';
import HraderDeliveryAddressNew from '../components/DTC/bento/HraderDeliveryAddress';
import * as COOKIE_VARIABLES from '../constants/cookieVariables';
import { BENTO, GRASSDOOR } from '../constants/website';
import { ASAP, SCHEDULE } from '../constants/deliveryType';
import notificationTypes from '../constants/modalNotificationConst';
import fetchServerResponse from '../helpers/serverHelper';
import categoriesHelper from '../helpers/categoriesHelper';
// import appConfig from '../appConfig';

import {
  useASAPShop,
  useScheduleShop,
  useShopFilters,
  useBannerImages,
  useFullCategoryList,
  useASAPBundelShop,
  useASAPBestSellerShop,
  useScheduleBestSellerShop,
  useAsapFeaturedShop,
  useScheduledFeaturedShop,
  useProductCount
} from '../hooks/Shop';
import { useDeliveryDetails } from '../hooks/app';
import appContext from '../Context/appContext';

import CategoryMenu from '../components/DTC/CategoryMenu';

import { DEFAULT_CATEGORY_MENU, DEFAULT_LOCATION, DEFAULT_ZIPCODE, DEFAULT_ZONE_ID } from '../constants/default';
import useSegment from '../hooks/segment';
import StorageService from '../services/storageService';

import getHost from '../helpers/gethost';
import { matchFilter } from '../helpers/filterHelper';
import MiniTabs from '../components/DTC/grassdoor/MiniTabs';
import { setFirstBanner } from '../helpers/bannerHelper';
import ShopMenu from '../components/ShopMenu';
import Zipcodes from '../components/DTC/grassdoor/Zipcodes';
import { getFaqSchema, removeHtmlTagsFromStr } from '../helpers/getFaqSchema';
import SelectedFiltersList from '../components/SelectedFiltersList';
import {
  businessSchema,
  serviceSchema,
  onlineStoreSchema
} from '../constants/schema';
import SearchTabs from '../components/DTC/grassdoor/SearchTabs';

import { asapBundleSlug, asapMinProductCount } from '../constants/asapBundles';
import {
  isBestSellerApplicable,
  isSortByDealFilterApplicable,
  isFeaturedProductApplicable,
  isAsapBundlesApplicable,
  isNewHomePageApplicable
} from '../constants/feature';
import { removeTags, updateFilterList } from '../hooks/common';
import SeeMore from '../components/SeeMore';
import SearchButton from '../components/searchModule/SearchButton';
import { useDispatch, useSelector } from 'react-redux';
import { increaseURLstack, pushIfNotPresent } from '../redux/slices/modalSlice';
import { setAsapProductInfo, setFetchProductOnLoginFromPopupInfo, setProductFetchInfo, setScheduleProductInfo, setUrlBeforeCompare } from '../redux/slices/userSlice';
import { setOnLandingPage } from '../redux/slices/otherSlice';
import { setFullCategoryList } from '../redux/slices/sidebar';
import DeliveryTypeToggle from '../components/DeliveryTypeToggle';
import ScrollToTop from '../components/ScrollToTop';
import { withConfig } from '../lib/withConfig';
import { useConfigData } from '../Context/ConfigProvider';
import { isFilterApplied } from '../helpers/checkFilter';
import CustomTextDisplay from '../components/CustomTextDisplay';
import DeliveryTiming from '../components/DeliveryTiming';
import DeliveryTimingSchedule from '../components/DeliveryTimingSchedule';

const canonicalHref = `https://${getHost()}/`; // used for og:url as well
let modifyScheduleClient = false;
export const Shop = props => {
  const {
    asapCategories: asapCategoriesInitial,
    scheduleCategories: scheduleCategoriesInitial,
    bannerData: initialBannerData,
    fullCategoriesList: initialFullCategoriesList,
    seoData: initialSeoData,
    filterList: initialFilters,
    initialMaxPrice,
    initialMinPrice,
    intialScheduleProductCount,
    intialAsapProductCount,
    initialLocationAddress = false,
    initialFreightLimit = false,
    initialPurchaseLimit = false,
    initialUpsellProducts = false,
    initialDeliveryAddressDetails = false,
    initialAnnouncmentDetails = false,
    asapBestSellerCategories: initialAsapPopularProducts,
    scheduleBestSellerCategories: initialSchedulePopularProducts,
    brandsList,
    deliveringCities,
    topBrands = [],
    zipCodes = [],
    homeFaq,
    homeSchema,
    asapBundlesInitial,
    intialAsapBundlesCount,
    initialAsapFeaturedProducts,
    initialScheduledFeaturedProducts,
    bannerPreData
  } = props;

  const {
    state: {
      shop: { shopFilters },
    },
    dispatch
  } = useContext(appContext);
  const appConfig = useConfigData();
  const { seoData = initialSeoData, miniTabs } = useSelector(state => state.other)
  const compare = useSelector(state => state.compare)
  const { couponData, isLoggedIn, shouldFetchProductOnLoginFromPopup, searchTabs } = useSelector(state => state.user)
  const dispatchRedux = useDispatch()
  const { data: filterList = [], fullShopCategories, isValidating } = useShopFilters({ initialFilters, initialMinPrice, initialMaxPrice });
  const {
    data: { asapFilterData, scheduleFilterData }
  } = useProductCount();
  const schedulePaging = useRef(true);
  const [isFilterPopupVisible, toggleFilterPopup] = useState(false);
  const [isSolid, setSolid] = useState(false);
  const [waitingFlag, setWaitingFlag] = useState(true);
  const [initial, setInitial] = useState(true);
  const [filters, setFilters] = useState(filterList || shopFilters);
  const [page, setPage] = useState(1);
  const [first, setfirst] = useState(true);
  const [showAsapOnly, setShowAsapOnly] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(true);

  useEffect(() => {
    if (!isValidating && filters && filterList) {
      const newFilters = matchFilter(filters, filterList);
      setFilters(updateFilterList(newFilters, currentTab === ASAP ? asapFilterData : scheduleFilterData));
    }
  }, [isValidating, scheduleFilterData, asapFilterData]);

  const trackedCategories = useRef([]);

  const triggeredAPi = useCallback(() => {
    dispatchRedux(setProductFetchInfo({ shouldFetchProduct: true }))
  }, [])

  useEffect(() => {
    dispatchRedux(setOnLandingPage(false))
  }, []);

  const {
    data: asapCategories,
    isLoading: isLoadingAsap,
    isValidating: isValidatingAsap,
    asapProductsCount,
    mutate: mutateAsapShop
  } = useASAPShop({
    asapCategoriesInitial,
    filters,
    intialAsapProductCount
  });


  const { data: asapBundleCategories, asapBundlesCount } = useASAPBundelShop({
    asapBundlesInitial,
    filters,
    intialAsapBundlesCount
  });

  const {
    data: scheduleCategories,
    isLoading: isLoadingSchedule,
    isValidating: isValidatingSchedule,
    scheduledProductsCount,
    modifySchedule,
    mutate: mutateScheduleShop,
  } = useScheduleShop({
    scheduleCategoriesInitial,
    filters,
    intialScheduleProductCount,
    page
  });

  const {
    data: asapPopularCategories,
    isLoading: asapPopularCategoriesLoading,
    isValidating: asapPopularCategoriesValidating
  } = useASAPBestSellerShop({
    initialAsapPopularProducts,
    filters
  });

  const {
    data: schedulePopularCategories,
    isLoading: schedulePopularCategoriesLoading,
    isValidating: schedulePopularCategoriesValidating
  } = useScheduleBestSellerShop({
    initialSchedulePopularProducts,
    filters
  });

  const {
    data: asapFeaturedProducts,
    isLoading: asapFeaturedProductsLoading,
    isValidating: asapFeaturedProductsValidating
  } = useAsapFeaturedShop({
    initialAsapFeaturedProducts
  });

  const {
    data: scheduledFeaturedProducts,
    isLoading: scheduledFeaturedProductsLoading,
    isValidating: scheduledFeaturedProductsValidating
  } = useScheduledFeaturedShop({
    initialScheduledFeaturedProducts
  });
  useEffect(() => {
    if (shouldFetchProductOnLoginFromPopup) {
      setPage(1);
      mutateAsapShop();
      mutateScheduleShop();
    }
    dispatchRedux(setFetchProductOnLoginFromPopupInfo({ shouldFetchProductOnLoginFromPopup: false }))
  }, [shouldFetchProductOnLoginFromPopup]);

  useEffect(() => {
    if (isLoggedIn && !shouldFetchProductOnLoginFromPopup) {
      setPage(1);
      mutateAsapShop();
      mutateScheduleShop();
    }
  }, [isLoggedIn]);


  useEffect(() => {
    return () => {
      modifyScheduleClient = false;
    };
  }, []);

  useEffect(() => {
    if (modifySchedule === true) {
      modifyScheduleClient = true;
      paginateCategoryData();
    }
  }, [modifySchedule]);

  const {
    data: {
      asapEnabled = true,
      scheduleEnabled = true,
      deliveryDays: scheduleDetails = [],
      unified_menu: unifiedMenu,
      asapStartTime,
      asapEndTime,
      lastSlotCutOff
    } = {},
    data: deliveryDetailsData = {},
    isLoading: isAddressLoading
  } = useDeliveryDetails(initialDeliveryAddressDetails);

  if (!appConfig.isGrassdoor && unifiedMenu) {
    const asapCategoriesCompare = asapCategories;
    const scheduleCategoriesCompare = scheduleCategories;

    if (showAsapOnly) {
      asapCategoriesCompare.forEach(asap => {
        asap.products.map(item => (item.showTag = 1));
      });
      var asapOnlyCats = asapCategoriesCompare;
    } else {
      asapCategoriesCompare.forEach(asap => {
        asap.products.map(item => (item.showTag = 1));
        for (var index in scheduleCategories) {
          if (asap.id === scheduleCategories[index].id) {
            asap.products.map(item => (item.showTag = 1));
            asap.products.forEach(function (element, key, object) {
              scheduleCategories[index].products.forEach(scheduleProducts => {
                if (
                  asap.id === scheduleCategories[index].id &&
                  element.product_details_id === scheduleProducts.product_details_id &&
                  element.is_sold_out == 1 &&
                  scheduleProducts.is_sold_out == 0
                ) {
                  scheduleProducts.showTag = 0;
                  element.showTag = 0;
                  element.delete = true;
                }
              });
            });
            let products = remove(asap.products, function (n) {
              return n.delete == true;
            });
            products = asap.products.concat(scheduleCategories[index].products);
            products = uniqBy(products, 'product_details_id');
            asap.products = products;
            return;
          }
        }
      });

      scheduleCategoriesCompare.forEach(schedule => {
        for (const index in asapCategories) {
          if (schedule.id === asapCategories[index].id) {
            let products = asapCategories[index].products.concat(schedule.products);
            products = uniqBy(products, 'product_details_id');
            schedule.products = products;
            return;
          }
        }
      });

      var finalCategories = asapCategoriesCompare.concat(scheduleCategoriesCompare);
      finalCategories = uniqBy(finalCategories, 'id');
    }
  }

  const { data: bannerData } = useBannerImages({ initialBannerData: bannerPreData });
  const { data: fullCategoryList = DEFAULT_CATEGORY_MENU, isLoading: isLoadingFullCategoryList } = useFullCategoryList({
    initialFullCategoriesList
  });

  const router = useRouter();
  const {
    query: { tab }
  } = router;
  const { tab: pathName } = router.query;
  useEffect(() => {
    if (first) return;
    setFilters(updateFilterList(filters, currentTab === ASAP ? asapFilterData : scheduleFilterData));
  }, [tab]);
  useEffect(() => {
    triggeredAPi()
  }, [tab])
  let currentTab = tab || getDefaultTab({ lastSlotCutOff, asapStartTime, asapEndTime, asapEnabled });

  if (!asapEnabled) {
    currentTab = SCHEDULE;
  }

  if (!scheduleEnabled) {
    currentTab = ASAP;
  }

  let bothTypeSchedule = false;
  if (scheduleEnabled && asapEnabled) {
    bothTypeSchedule = true;
  }

  useEffect(() => {
    if (currentTab == ASAP && pathName == SCHEDULE && !bothTypeSchedule && !isAddressLoading) {
      router.push(`/?tab=${ASAP}`);
    } else if (currentTab == SCHEDULE && pathName == ASAP && !bothTypeSchedule && !isAddressLoading) {
      router.push(`/?tab=${SCHEDULE}`);
    }
  }, [pathName, currentTab, bothTypeSchedule, isAddressLoading]);

  useEffect(() => {
    if (first && filterList) {
      setfirst(false);
      setFilters(updateFilterList(filterList, currentTab === ASAP ? asapFilterData : scheduleFilterData));
    }
  }, [filterList]);

  useEffect(() => {
    if (currentTab === ASAP) {
      setPage(1);
    }

    const interval = setTimeout(() => {
      if (currentTab == ASAP) {
        dispatchRedux(setScheduleProductInfo({ shouldFetchSchedule: true }))
      } else {
        dispatchRedux(setAsapProductInfo({ shouldFetchAsap: true }))

      }
    }, 5000);

    return () => clearTimeout(interval);
  }, []);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (currentTab == ASAP) {
        dispatchRedux(setAsapProductInfo({ shouldFetchAsap: true }))
      } else {
        dispatchRedux(setScheduleProductInfo({ shouldFetchSchedule: true }))
      }
    }, 1000);

    return () => clearTimeout(interval);
  }, []);

  useEffect(() => {
    // set below variables to false on unmount, so it's functionality may be used on other pages
    return () => {
      dispatchRedux(setAsapProductInfo({ shouldFetchAsap: false }))
      dispatchRedux(setScheduleProductInfo({ shouldFetchSchedule: false }))
    };
  }, []);

  let filterApplied = false;
  if (filters) {
    filters.forEach(element => {
      if (
        element.type === 'sort_key' &&
        (!element.values[0].checked || (element.values[0].checked && element.values[0]?.intended))
      ) {
        filterApplied = true;
      }
      if (element.type === 'search' && filter(element.values, { checked: true }).length > 0) {
        filterApplied = true;
      }
      if (element.name === 'priceRange' && element.isSet == true) {
        filterApplied = true;
      }
    });
  }

  const setTab = useCallback((newTab) => {
    if (tab !== newTab) {
      const href = `${router.pathname}?tab=${newTab}`;
      router.replace(href, undefined, { shallow: true });
    }
  }, [tab])

  const paginateCategoryData = useCallback(() => {
    if (filterApplied) return;
    if (currentTab == SCHEDULE && page === 1 && schedulePaging.current === true && modifyScheduleClient === true) {
      dispatchRedux(setScheduleProductInfo({ shouldFetchSchedule: true }))
      setPage(2);
    }
  }, [filterApplied, currentTab, page, modifyScheduleClient]);
  const handleScroll = useCallback(() => {
    if (window.scrollY > 200) {
      paginateCategoryData();
      tab == "schedule" && document.removeEventListener('scroll', handleScroll, { passive: true });
    }
  }, [tab]);

  const applyFilters = useCallback(updatedFilters => {
    if (!filters) {
      setFilters(filterList);
    } else {
      schedulePaging.current = false;
      setPage(false);
      setInitial(false);
      setFilters(updatedFilters);
    }
  }, [filters, filterList]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      document.removeEventListener('scroll', handleScroll, { passive: true });
    };
  }, [handleScroll]);

  const { trackPage, trackEvent } = useSegment();

  useEffect(() => {
    if (process.browser) {
      trackPage(currentTab === SCHEDULE ? 'Schedule Shop Page' : 'Shop Full Page');
    }
  }, [currentTab]);

  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: 'setFilters', payload: { shopFilters: filters } });
    }, 100);
  }, [filters]);

  const { discount_code: discountCode } = couponData;

  const debouncedTrack = useCallback((data, categoryIndex, noProductFound = false) => {
    const latestTracked = trackedCategories.current;
    const filterTypes = [];
    const sortTypes = [];
    if (noProductFound) {
      data.id = 'no-product-found';
    }

    filters &&
      filters
        .filter(comp => comp.type == 'sort_key')
        .map(item => {
          item.values
            .filter(cmp => cmp.checked == true)
            .map(sortItem => {
              if (!(sortItem.id == 'asc' && sortItem.name == 'product_price' && initial)) {
                sortTypes.push({ type: sortItem.name, value: sortItem.value });
              }
            });
        });
    filters &&
      filters
        .filter(comp => comp.type == 'search')
        .map(item => {
          item.values
            .filter(cmp => cmp.checked == true)
            .map(filterItem => {
              filterTypes.push({ type: item.title, value: filterItem.value });
            });
        });

    const type =
      sortTypes && sortTypes.length && filterTypes && filterTypes.length ? 'Product List Filtered' : 'Product List Viewed';
    const checkedIfEventExists = latestTracked.filter(
      item => item.id == data?.id && item.type == type && JSON.stringify(item.filterTypes) == JSON.stringify(filterTypes)
    );
    if (checkedIfEventExists.length > 0) {
      return null;
    }
    trackedCategories.current = [
      ...latestTracked,
      {
        id: data?.id,
        type,
        filterTypes
      }
    ];

    const origin = window.location.origin;
    const products = [];
    let categoryName = '';
    categoryName = noProductFound ? 'No product found' : data?.category_name;
    data?.products?.forEach((item, index) => {
      if (index < 5) {
        products.push({
          product_id: item.product_details_id,
          sku: item.bundle_id ? item.bundle_id : item.master_product_id,
          name: item.product_name,
          strain_type: item.product_strain_type_name || '',
          price: item.price,
          original_price: item.price_without_deal,
          position: index,
          category_position: categoryIndex,
          category_row_position: index,
          product_attributes: Array.isArray(item.product_attributes_second) ? item.product_attributes_second.join('/') : '',
          brand: Array.isArray(item.brand_names) ? item.brand_names.join('/') : item.brand_slug,
          variant: item.product_unit,
          quantity: 1,
          coupon: discountCode || '',
          category_frontend: item.category_name,
          category: item.full_shop_category_name,
          url: `${origin}/product/${item.slug}`,
          image_url: item.product_image
        });
      }
    });
    const zoneName = StorageService.getZoneLocalStorage();
    trackEvent(type, {
      list_id: SCHEDULE ? 'Schedule List' : 'ASAP List',
      category: categoryName,
      sort: sortTypes && sortTypes.length ? sortTypes : [],
      filter: filterTypes && filterTypes.length ? filterTypes : [],
      products,
      zone_name: zoneName,
      best_seller: data.isMostPopular
    });

    return null;
  }, [filters])

  useEffect(() => {
    if (process.browser && waitingFlag) {
      setWaitingFlag(false);
      setTimeout(() => {
        setWaitingFlag(true);
      }, 5000);
    }
  }, [currentTab, filters]);

  const { site_info: siteInfo = {} } = seoData || {};
  const {
    meta_title: metaTitle,
    meta_description: metaDescription,
    og_title: ogTitle,
    og_description: ogDescription,
    og_image: ogImage
  } = siteInfo || {};

  const singleCategory = [];
  let selectedTabCategories = [];
  let otherTabCategories = [];
  if (currentTab == ASAP && asapCategories?.length) {
    selectedTabCategories = [...asapCategories];
    if (scheduleCategories?.length) {
      singleCategory.push(scheduleCategories[0]);
      otherTabCategories = [...scheduleCategories];
    }
  }

  if (currentTab == SCHEDULE && scheduleCategories?.length) {
    selectedTabCategories = [...scheduleCategories];
    if (asapCategories?.length) {
      singleCategory.push(asapCategories[0]);
      otherTabCategories = [...asapCategories];
    }
  }

  const mostPopularCatIndex = {
    isBuyItAgain: 1,
    noneAvailable: 0
  };

  if (isBestSellerApplicable && currentTab === ASAP) {
    if (asapPopularCategories && asapPopularCategories.length && asapCategories && asapCategories.length) {
      const isBuyItAgain = asapCategories.findIndex(i => i.category_name === 'Buy It Again');
      const checkifAsapPopularCategoriesExist = asapCategories.filter(item => item.isMostPopular == true);
      if (isBuyItAgain >= 0) {
        if (checkifAsapPopularCategoriesExist && checkifAsapPopularCategoriesExist.length === 0) {
          asapCategories?.splice(mostPopularCatIndex.isBuyItAgain, 0, ...asapPopularCategories);
        } else {
          const removeIndex = asapCategories.findIndex(item => item.isMostPopular == true);
          asapCategories?.splice(removeIndex, 1);
          asapCategories?.splice(mostPopularCatIndex.isBuyItAgain, 0, ...asapPopularCategories);
        }
      } else if (checkifAsapPopularCategoriesExist && checkifAsapPopularCategoriesExist.length == 0) {
        asapCategories?.splice(mostPopularCatIndex.noneAvailable, 0, ...asapPopularCategories);
      }
    }
  }

  if (isBestSellerApplicable && currentTab === SCHEDULE) {
    if (schedulePopularCategories && schedulePopularCategories.length && scheduleCategories && scheduleCategories.length) {
      const isBuyItAgain = scheduleCategories.findIndex(i => i.category_name === 'Buy It Again');
      const checkifSchedulePopularCategoriesExist = scheduleCategories.filter(item => item.isMostPopular == true);
      if (isBuyItAgain >= 0) {
        if (checkifSchedulePopularCategoriesExist && checkifSchedulePopularCategoriesExist.length === 0) {
          scheduleCategories?.splice(mostPopularCatIndex.isBuyItAgain, 0, ...schedulePopularCategories);
        } else {
          const removeIndex = scheduleCategories.findIndex(item => item.isMostPopular == true);
          scheduleCategories?.splice(removeIndex, 1);
          scheduleCategories?.splice(mostPopularCatIndex.isBuyItAgain, 0, ...schedulePopularCategories);
        }
      } else if (checkifSchedulePopularCategoriesExist && checkifSchedulePopularCategoriesExist.length == 0) {
        scheduleCategories?.splice(mostPopularCatIndex.noneAvailable, 0, ...schedulePopularCategories);
      }
    }
  }

  const asapBundlesIndex = {
    noneAvailable: 0,
    onlyBuyItAvailable: 1,
    onlyPopularProdcutsAvailable: 1,
    bothAvailable: 2
  };
  if (
    isAsapBundlesApplicable &&
    currentTab === ASAP &&
    asapBundleCategories &&
    asapBundleCategories.length &&
    asapBundlesCount >= asapMinProductCount
  ) {
    const isBuyItAgain = asapCategories.findIndex(i => i.category_name === 'Buy It Again');
    const checkifAsapPopularCategoriesExist = asapCategories.filter(item => item.isMostPopular == true);
    const checkifAsapBundleExist = asapCategories.filter(item => item.category_slug == asapBundleSlug);
    if (isBuyItAgain >= 0) {
      if (!checkifAsapBundleExist.length && checkifAsapPopularCategoriesExist.length) {
        asapCategories.splice(asapBundlesIndex.bothAvailable, 0, ...asapBundleCategories);
      } else if (!checkifAsapBundleExist.length && !checkifAsapPopularCategoriesExist.length) {
        asapCategories.splice(asapBundlesIndex.onlyBuyItAvailable, 0, ...asapBundleCategories);
      }
    } else if (!checkifAsapBundleExist.length && checkifAsapPopularCategoriesExist.length) {
      asapCategories.splice(asapBundlesIndex.onlyPopularProdcutsAvailable, 0, ...asapBundleCategories);
    } else if (!checkifAsapBundleExist.length && !checkifAsapPopularCategoriesExist.length) {
      asapCategories.splice(asapBundlesIndex.noneAvailable, 0, ...asapBundleCategories);
    }
  }

  let featuredProducts = null;
  if (isFeaturedProductApplicable) {
    featuredProducts = currentTab === ASAP ? asapFeaturedProducts : scheduledFeaturedProducts;
  }

  const handleFloatingButtonClick = useCallback(() => {
    const url = '/compare';
    dispatchRedux(pushIfNotPresent({ type: notificationTypes.comparePopup, data: { source: 'Floating widget' } }))
    dispatchRedux(increaseURLstack())
    dispatchRedux(setUrlBeforeCompare({ urlBeforeCompare: window.location.href }))
    window.history.replaceState({ ...window.history.state, as: url, url }, '', url);
  }, []);

  const fetching =
    currentTab === ASAP
      ? (isValidatingAsap && asapPopularCategoriesValidating && asapFeaturedProductsValidating) || isLoadingAsap
      : (isValidatingSchedule && schedulePopularCategoriesValidating && scheduledFeaturedProductsValidating) || isLoadingSchedule;

  const [showText, setShowText] = useState(true);
  const [divIsOverflowed, setDivIsOverflowed] = useState(false);

  const seeMore = React.useCallback(() => {
    setShowText(!showText);
  }, [showText]);
  const isEllipsisActive = e => {
    return e?.clientWidth < e?.scrollWidth || e?.clientHeight < e?.scrollHeight;
  };

  useEffect(() => {
    const seeMoreDiv = document.querySelector('.bottom-catalog-listing');
    setDivIsOverflowed(isEllipsisActive(seeMoreDiv));
  }, [showText]);

  const getAsapCount = (asap, bundles) => {
    if (bundles >= asapMinProductCount) {
      return asap + bundles;
    }
    return asap;
  };
  const ref = useRef(null);
  return (
    <>
      <Head>
        <title>{metaTitle || appConfig.META_TITLE}</title>
        <meta name="description" content={metaDescription || appConfig.META_DESCRIPTION} />
        <meta property="og:title" content={ogTitle || appConfig.META_TITLE} />
        <meta property="og:description" content={ogDescription || appConfig.META_DESCRIPTION} />
        <meta property="og:image" content={ogImage || appConfig.META_IMAGE} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalHref} />
        <link rel="canonical" href={canonicalHref} />
        {([GRASSDOOR].includes(process.env.NEXT_PUBLIC_APP) || process.env.NEXT_PUBLIC_DEFAULT_SITES) &&
          (homeSchema ? (
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(homeSchema) }} />
          ) : null)}
        {process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: serviceSchema
            }}
          />
        )}
        {process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: onlineStoreSchema
            }}
          />
        )}
        {process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: businessSchema
            }}
          />
        )}
      </Head>
      <Header
        hasShadow={isSolid}
        hasWhiteBg={isSolid}
        showLogin={!isNewHomePageApplicable}
        centerComponent={<HraderDeliveryAddress hideOnLarge />}
        shopFullMenuButton={false}
        onClickFilter={() => toggleFilterPopup(true)}
        showFilter={!isNewHomePageApplicable}
        filterApplied={filterApplied}
        currentTab={currentTab}
        asapProductsCount={getAsapCount(asapProductsCount, asapBundlesCount)}
        scheduledProductsCount={scheduledProductsCount}
        setTab={setTab}
        initialLocationAddress={initialLocationAddress}
        initialDeliveryAddressDetails={initialDeliveryAddressDetails}
        initialAnnouncmentDetails={initialAnnouncmentDetails}
        initialAsapCategories={asapCategoriesInitial}
        initialScheduleCategories={scheduleCategoriesInitial}
        showUserInfo={isNewHomePageApplicable}
        showDeliverySpecificMessage={isNewHomePageApplicable}
        showHappyHours
        showProfile
        help={isNewHomePageApplicable}
      />
      {isNewHomePageApplicable ? (
        <SearchTabs
          enabled={searchTabs}
          fullCategoryList={fullCategoryList}
          setTab={setTab}
          currentTab={currentTab}
          defaultSearchTab="schedule"
          initialLocationAddress={initialLocationAddress}
        >
          <SelectedFiltersList filtersList={shopFilters} filtersType="shopFilter" />
        </SearchTabs>
      ) : (
        <MiniTabs
          enabled={miniTabs}
          fullCategoryList={fullCategoryList}
          currentTab={currentTab}
          asapProductsCount={getAsapCount(asapProductsCount, asapBundlesCount)}
          scheduledProductsCount={scheduledProductsCount}
          asapEnabled={asapEnabled}
          scheduleEnabled={scheduleEnabled}
          setTab={setTab}
          className="category-page-tabs"
        >
          <SelectedFiltersList filtersList={shopFilters} filtersType="shopFilter" />
        </MiniTabs>
      )}

      <div className="shop-full-menu">
        <div className='mobile-toggle px-2'>
          <div>
            <div className='flex justify-center w-full items-center gap-4'>
              <div id="search-category-wrapper" className={`search-category-wrapper mt-2 flex-grow max-w-[780px]`}>
                <div className={`searchBox`}>
                  <div className="new-search-component">
                    <div className="input-group searchBar">
                      <div className="input-group searchBar">
                        <HraderDeliveryAddressNew />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DeliveryTypeToggle
            currentTab={currentTab}
            setTab={setTab}
            isAsapDisabled={false}
            isScheduleDisabled={!scheduleEnabled}
            hideToggle={!asapEnabled || !scheduleEnabled}
            displayForMobile
          />

          <div className='text-center block md:hidden  mt-[12px] md:mt-0'>
            {(
              currentTab === ASAP ? (
                <DeliveryTiming currentTab="asap" onlyText fullText />
              ) : (
                <DeliveryTimingSchedule currentTab={currentTab} onlyText fullText />
              )
            )
            }
          </div>
        </div>
        {isNewHomePageApplicable && fullCategoryList ? (
          <CategoryMenu fullCategoryList={fullCategoryList} />
        ) : null}
        <BannerCustom bannerData={bannerData} currentTab={currentTab} />
        {/* <div className='text-center hidden md:block md:mt-4'>
          {
            currentTab === ASAP ?
              <DeliveryTiming currentTab={"asap"} onlyText fullText />
              :
              <DeliveryTimingSchedule onlyText currentTab={currentTab} fullText />
          }
        </div> */}
        {/* {isNewHomePageApplicable ? <Banner bannerData={bannerData} /> : null} */}
        {isNewHomePageApplicable ? <SearchButton toggleFilterPopup={toggleFilterPopup} hideFilter /> : null}
        {/* <CustomTextDisplay data={deliveryDetailsData} currentTab={currentTab}>
          <></>
        </CustomTextDisplay> */}
        {/* {isNewHomePageApplicable ? <BannerSlider bannerData={bannerData} /> : null} */}
        {!isNewHomePageApplicable ? (
          <Banner
            bannerData={bannerData}
            onlyHomePage
            initialLocationAddress={initialLocationAddress}
            initialDeliveryAddressDetails={initialDeliveryAddressDetails}
          >
            <AddressBox />
          </Banner>
        ) : null}
        {!isNewHomePageApplicable && fullCategoryList ? (
          <CategoryMenu fullCategoryList={fullCategoryList} />
        ) : null}


        <div className="container default-site-products drinkcann-container zipps-landing-container">
          <ShopMenu
            bannerData={bannerData}
            initialLocationAddress={initialLocationAddress}
            initialDeliveryAddressDetails={initialDeliveryAddressDetails}
            currentTab={currentTab}
            asapProductsCount={getAsapCount(asapProductsCount, asapBundlesCount)}
            scheduledProductsCount={scheduledProductsCount}
            filters={filters}
            setFilters={list => setFilters(updateFilterList(list, currentTab === ASAP ? asapFilterData : scheduleFilterData))}
            setPage={setPage}
            setInitial={setInitial}
            unifiedMenu={unifiedMenu}
            setShowAsapOnly={setShowAsapOnly}
            showAsapOnly={showAsapOnly}
            asapEnabled={asapEnabled}
            scheduleEnabled={scheduleEnabled}
            setTab={setTab}
            toggleFilterPopup={toggleFilterPopup}
            filterApplied={filterApplied}
            isSolid={isSolid}
            shopFilters={shopFilters}
            finalCategories={finalCategories}
            asapOnlyCats={asapOnlyCats}
            fetching={fetching}
            debouncedTrack={debouncedTrack}
            initialFreightLimit={initialFreightLimit}
            initialPurchaseLimit={initialPurchaseLimit}
            initialUpsellProducts={initialUpsellProducts}
            filterList={filterList}
            filterCountData={currentTab === ASAP ? asapFilterData : scheduleFilterData}
            asapCategories={asapCategories}
            scheduleCategories={scheduleCategories}
            isFilterPopupVisible={isFilterPopupVisible}
            applyFilters={applyFilters}
            topBrands={topBrands}
            compare={compare}
            handleFloatingButtonClick={handleFloatingButtonClick}
            from="home"
            fromPage="HomePage"
            scrollDirectionUp={false}
            featuredProducts={featuredProducts}
            showSpecialCategory
            lastSlotCutOff={lastSlotCutOff}
            asapEndTime={asapEndTime}
            asapStartTime={asapStartTime}
            key={currentTab}
          />
        </div>

        {isNewHomePageApplicable && showScrollButton ? (
          <ScrollToTop />
        ) : null}

        <button
          onClick={() => toggleFilterPopup(true)}
          className={`scroll-to-top filter relative`}
        >
          <span className="icon icon-filter-dark-i text-[12px]" />
          {isFilterApplied(shopFilters) ? <div className='h-[8px] w-[8px] rounded-[50%] bg-red-600 absolute right-0 top-0' /> : null}
        </button>
        {isNewHomePageApplicable && false ? (
          <div>
            <div className="seo-section d-flex justify-content-center align-items-center mb-lg-5 mb-4 mt-0">
              {[WEBSITES.BENTO, WEBSITES.GRASSDOOR].includes(process.env.NEXT_PUBLIC_APP) ? (
                <img
                  className="seo-image mr-lg-4 mr-3"
                  width={40}
                  height={50}
                  src={`/static/images/${appConfig.isGrassdoor ? 'badge-vector1' : 'badge-vector_bento'}.svg`}
                  alt="seo-tag"
                  loading='lazy'
                />
              ) : null}
              <div className="d-lg-flex justify-lg-content-center align-items-center flex-wrap">
                <h1 className="mb-0">Weed Delivery Near You in California </h1>
                <span className="line d-none d-lg-block"> </span>
                <h2 className="mb-0">Quality Cannabis Products Everytime</h2>
              </div>
            </div>
          </div>
        ) : null}
        {[WEBSITES.GRASSDOOR, WEBSITES.FLOWERANDEDIBLES, WEBSITES.DEFAULT].includes(process.env.NEXT_PUBLIC_APP) && (
          <>
            <div className={isNewHomePageApplicable ? 'bottom-sec-wrapper' : ''} ref={ref}>
              {/* <BottomCatalog
                contentLink={brandsList}
                pageLink="brands"
                section="brands"
                heading="The Cannabis Brands You Love"
                para="Choose from the widest variety of weed products from the best brands.
                    Our stocks are loved, classic brands like <a href='/brands/pacific-stone'>Pacific Stone</a>, <a href='/brands/pax'>PAX</a>, <a href='/brands/kiva-confections'>Kiva Confections</a>, and <a href='/brands/raw-garden'>Raw Garden</a>,
                    plus newcomers and groundbreakers like <a href='/brands/wyld'>WYLD</a>, <a href='/brands/cann'>CANN</a>, <a href='/brands/wunder'>Wunder</a>, and <a href='/brands/dosist'>dosist</a>.
                    Check the full list of cannabis brands that can be delivered near you in California."
              />

              {isNewHomePageApplicable ? <HowGrassdoorWorks /> : null}
              <WhyChooseUs />
              {isNewHomePageApplicable ? (
                <FAQListingNew faqList={homeFaq} />
              ) : (
                <div className="faq-container">
                  {homeFaq.length ? (
                    <>
                      <h2 className="bold text-center">FAQs</h2>
                      <div className="faq-wrapper container">
                        {homeFaq.map((faq, key) => {
                          return key <= (isMobile() ? 0 : 3) ? (
                            <div key={key} className="faq-qna">
                              <div className="faq-quest">
                                <p>{faq.question}</p>
                              </div>
                              <div className="faq-ans">
                                {!isNewHomePageApplicable ? (
                                  <DangerousHTML html={faq.answer} />
                                ) : (
                                  <SeeMore text={removeTags(faq.answer)} />
                                )}
                              </div>
                            </div>
                          ) : null;
                        })}
                      </div>
                      {homeFaq.length > (isMobile() ? 1 : 4) ? (
                        <div className={`faq-wrapper faq-wrapper-hidden container ${showText ? '' : 'seeMore'}`}>
                          {homeFaq.map((faq, key) => {
                            return key > (isMobile() ? 1 : 3) ? (
                              <div key={key} className="faq-qna">
                                <div className="faq-quest">
                                  <p>{faq.question}</p>
                                </div>
                                <div className="faq-ans">
                                  {!isNewHomePageApplicable ? (
                                    <DangerousHTML html={faq.answer} />
                                  ) : (
                                    <SeeMore text={removeTags(faq.answer)} />
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      ) : null}
                      {homeFaq.length > (isMobile() ? 1 : 4) ? (
                        <div className="more-btn container text-center">
                          {(divIsOverflowed || showText) && (
                            <button
                              className=" "
                              onClick={seeMore}
                              style={{ cursor: 'pointer', lineHeight: '24px', display: 'inline-block' }}
                            >
                              {!showText ? 'LESS ' : 'MORE'}
                            </button>
                          )}
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              )}

              <BottomCatalog
                contentLink={deliveringCities}
                pageLink={process.env.NEXT_PUBLIC_APP === WEBSITES.BENTO ? 'weed-delivery-near-me' : 'weed-delivery-near'}
                section="cities"
                heading="Weed Delivery Near Me"
                para="We offers weed delivery in California to more than 800 zip codes in 100+ cities. Check out our <a href='https://search.google.com/local/reviews?placeid=ChIJc9DG2yjPwoAR7c-KQDhNd2Y' class='google-review-link'>Google reviews</a>  to see why so many people love our products and services! We appreciate your feedback and can't wait to hear from you!"
              /> */}
              <Zipcodes
                contentLink={zipCodes.data}
                section="zipcodes"
                heading="List of Zipcodes We Serve"
                subHeading="We're constantly expanding our delivery range. Keep an eye out for our cannabis delivery service to come to your area and get the best weed delivered straight to you!"
              />
            </div>
          </>
        )}

      </div>
    </>
  );
};

const safeJsonParse = (str, defaultValue = []) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return defaultValue;
  }
};

async function getServerSidePropsFunction({ req, res, query: { tab } = {} }) {
  const cookies = new Cookies(req, res);
  const zipcode = cookies.get(COOKIE_VARIABLES.zipcode) || DEFAULT_ZIPCODE;
  const token = cookies.get(COOKIE_VARIABLES.token) || '';
  const latitude = cookies.get(COOKIE_VARIABLES.latitude) || DEFAULT_LOCATION.lat;
  const longitude = cookies.get(COOKIE_VARIABLES.longitude) || DEFAULT_LOCATION.lng;
  const placeId = cookies.get(COOKIE_VARIABLES.placeId) || '';

  const authorizationToken = token ? { authorization: `Bearer ${token}` } : {};

  const host = req?.headers?.host === 'localhost:3000'
    ? process.env.NEXT_PUBLIC_FALLBACK_API_URL
    : req?.headers?.host;
  const sortByDealFilter = isSortByDealFilterApplicable ? 'sort_key=deals_first&order=asc' : '';

  const headers = { 'x-origin': host, "saas-domain": host, zc: zipcode, placeId, lat: latitude, lng: longitude, ...authorizationToken };

  try {
    const deliveryResponse = await fetchServerResponse({
      url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/pre_fetch/delivery_details/${zipcode}`,
      method: 'GET',
      headers,
    });

    const { lastSlotCutOff, asapEndTime, asapStartTime, asapEnabled, asap_driver_id } = deliveryResponse.data || {};
    const currentTab = tab || getDefaultTab({ lastSlotCutOff, asapEndTime, asapStartTime, asapEnabled });

    if (currentTab !== 'asap' && currentTab !== 'schedule') {
      return {
        redirect: {
          destination: '/',
          permanent: false,
        },
      };
    }

    const initialDeliveryAddressDetails = deliveryResponse?.data || null;

    const apiUrls = [
      `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/${currentTab === SCHEDULE ? `${SCHEDULE}/` : ''}${zipcode}?shop_menu=false${sortByDealFilter ? `&${sortByDealFilter}${asap_driver_id ? `&asap_driver_id=${asap_driver_id}` : ``}` : ''}`,
      `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/bundle/${zipcode}?shop_menu=false&sort_key=product_price&order=asc`,
      `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/cms/carousel_details`,
      `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/categories/all_categories/${zipcode}`,
      `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/pre_fetch/logo`,
      `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/address/${zipcode}?lat=${latitude}&lng=${longitude}`,
      `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/customers/freight?latitude=${latitude}&longitude=${longitude}`,
      `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/customers/purchase_limits`,
      `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/customers/marketing_details`,
      `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/brands/all_brands`,
      `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/brands/top_brands`,
      `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/address/zipcodes`,
      `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/location/city`,
      `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/cms/homepage-faqs-section`,
    ];

    const apiRequests = apiUrls.map((url, index) => {
      const isAsapBundle = currentTab === ASAP && index === 1;
      return fetchServerResponse({
        url,
        method: 'GET',
        headers: isAsapBundle ? { ...headers, ...authorizationToken } : headers,
      });
    });

    const results = await Promise.allSettled(apiRequests);

    const [
      productsResult,
      asapBundleResult,
      bannerSeoResult,
      filterResult,
      fullCategoriesListResult,
      addressResult,
      freightResult,
      purchaseLimitResult,
      marketingResult,
      brandsResult,
      topBrandsResult,
      zipCodesResult,
      deliveringCitiesResult,
      homeFaqResult,
    ] = results;

    const getResultData = (result) => (result.status === 'fulfilled' ? result.value.data : null);

    const productsResponse = getResultData(productsResult);
    const asapBundleResponse = getResultData(asapBundleResult);
    const bannerSeoResponse = getResultData(bannerSeoResult);
    const filterResponse = getResultData(filterResult);
    const fullCategoriesListResponse = getResultData(fullCategoriesListResult);
    const addressResponse = getResultData(addressResult);
    const freightResponse = getResultData(freightResult);
    const purchaseLimitResponse = getResultData(purchaseLimitResult);
    const marketingResponse = getResultData(marketingResult);
    const brandsResponse = getResultData(brandsResult);
    const topBrandsResponse = getResultData(topBrandsResult);
    const zipCodesResponse = getResultData(zipCodesResult);
    const deliveringCitiesResponse = getResultData(deliveringCitiesResult);
    const homeFaqResponse = getResultData(homeFaqResult);

    let featuredProductResponse = null;
    if (isFeaturedProductApplicable) {
      const featuredProductResult = await fetchServerResponse({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/featured/products/${currentTab}`,
        method: 'GET',
        headers,
      });
      featuredProductResponse = featuredProductResult.data || null;
    }

    const processCategories = (response, isBundle) => {
      if (!response?.categories) return null;
      const categories = categoriesHelper(response.categories);
      if (isBundle) return categories?.slice(0, 4).map(c => ({ ...c, products: c.products.slice(0, 5) })) || [];
      return categories;
    };

    const [asapCategories, scheduleCategories] = [currentTab === ASAP, currentTab === SCHEDULE].map((isCurrent) =>
      processCategories(isCurrent ? productsResponse : null, true)
    );

    const commonProps = {
      seoData: bannerSeoResponse?.data || {},
      bannerData: setFirstBanner(bannerSeoResponse?.banner_images || {}),
      fullCategoriesList: fullCategoriesListResponse || [],
      filterList: filterResponse || {},
      fullBannerData: bannerSeoResponse?.data || {},
      initialDeliveryAddressDetails,
      initialLocationAddress: addressResponse || [],
      initialFreightLimit: freightResponse?.freightLimit || '-',
      initialPurchaseLimit: purchaseLimitResponse || null,
      initialMarketingDetails: marketingResponse?.marketing_details || {},
      initialUpsellProducts: {},
      brandsList: brandsResponse?.brands || [],
      topBrands: topBrandsResponse || [],
      zipCodes: zipCodesResponse || [],
      deliveringCities: deliveringCitiesResponse || [],
      homeFaq: safeJsonParse(homeFaqResponse?.description),
      homeSchema: homeFaqResponse
        ? {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: getFaqSchema(safeJsonParse(homeFaqResponse.description)),
        }
        : false,
      isLandingPageVisited: token,
      bannerPreData: bannerSeoResponse?.data?.banner_images || {}
    };

    return {
      props: {
        ...commonProps,
        asapCategories,
        scheduleCategories,
        intialAsapProductCount: currentTab === ASAP ? productsResponse?.product_menu_count || null : null,
        intialScheduleProductCount: currentTab === SCHEDULE ? productsResponse?.product_menu_count || null : null,
        intialAsapBundlesCount: currentTab === ASAP ? asapBundleResponse?.count || null : null,
        initialMaxPrice: currentTab === SCHEDULE ? productsResponse?.maxPrice || null : null,
        initialMinPrice: currentTab === SCHEDULE ? productsResponse?.minPrice || null : null,
        initialAsapFeaturedProducts: currentTab === ASAP ? featuredProductResponse || [] : null,
        initialScheduledFeaturedProducts: currentTab !== ASAP ? featuredProductResponse || [] : null,
      },
    };
  } catch (error) {
    console.error('shop.js_getServerSideProps_catch', error);
    if (error.status === 401) {
      return {
        redirect: {
          destination: '/login',
          permanent: false,
        },
      };
    }
    throw error;
  }
}

export const getServerSideProps = withConfig(getServerSidePropsFunction)